<template>
	<div class="teacher">
		<list-template
        :loading="loading"
			:total="total"
			:table-data="tableData"
			:table-config="tableConfig"
			:search-config="searchConfig"
			:current-page="page"
			@onSearch="onSearch"
			@onReset="search = null"
			@onChangePage="changeCurrentPage"
			@onHandle="tableHandle"
			hasAdd
			@onAdd="onAdd"
		></list-template>
	</div>
</template>

<script>
import {getGrade} from '@/api/account-management'
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading:true,
			// 表格搜索配置
			searchConfig: [
				{
					prop: 'grade_id',
					tag: 'select',
					placeholder: '请选择年级',
					options: []
				},
				{
					prop: 'class_name',
					placeholder: '请输入班级名称'
				}
			],
			// 表格配置
			tableConfig: [
				{
					prop: 'class_name',
					label: '教学班级名称'
				},
				{
					prop: 'subject_name',
					label: '科目'
				},
				{
					prop: 'hierarchy',
					label: '层级'
				},
				{
					prop: 'grade_name',
					label: '年级'
				},
				{
					label: '操作',
					handle: true,
					width: 120,
					render: () => ['编辑']
				}
			],
			search: null,
			total: 0,
			// 表格中的数据
			tableData: [],
			// 删除宿舍的id
			dormitoryId: ''
		}
	},
	computed: {
		...mapState(['page'])
	},
	mounted() {
		this.$store.commit('setPage', 1)
		this.getData()
	},
	activated() {
		this.getData()
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit('setPage', e)
			this.getData()
		},
		/**
		 * 获取数据
		 */
		getData() {
			let { search, page } = this;
      this.loading =  true;
			this.$_axios.get('teaching', { params: { ...search, page } }).then(res => {
				let { data } = res.data
				this.tableData = data
				let { total } = this.$tools.getPaginationInfo(res.headers)
				this.total = total
			}).finally(()=>this.loading = false);
		},
		// 新增按钮
		onAdd() {
			this.$router.push('./add')
		},
		// 搜索按钮被点击
		onSearch(val) {
			this.$store.commit('setPage', 1)
			this.search = val
			this.getData()
		},
		// 表格后面操作被点击
		tableHandle(row, handle, index) {
				this.$router.push({ path: './edit', query: { id: row.id } })
		}
	},
	created() {
		getGrade().then(res => {
			this.searchConfig[0].options = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label'))
		})
	}
}
</script>

<style scoped lang="scss">
.list {
	padding: 24rem 0 0 32rem;
	.search {
		.el-input,
		.el-select {
			width: 240rem;
			margin-right: 24rem;
		}
	}
	.pagination {
		margin-top: 50rem;
	}
}
</style>
